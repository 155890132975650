import type { _ActionsTree, _GettersTree, StateTree, Store } from 'pinia';
import { createPinia } from 'pinia';
import PiniaLogger from 'pinia-logger';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import type { ErrorMessageModel } from '@/types';

export interface BaseState<T> {
  data: T | null;
  errors: ErrorMessageModel[];
  isLoading: boolean;
}

export interface EntityState<T> {
  data: T[];
  errors: ErrorMessageModel[];
  isLoading: boolean;
}

export const stores: Store<string, StateTree, _GettersTree<StateTree>, _ActionsTree>[] = [];

export const pinia = createPinia()
  .use(
    PiniaLogger({
      showDuration: true,
      expanded: false,
      showStoreName: true,
      showTime: true,
      disabled: import.meta.env.VITE_APP_USE_LOGS === 'false',
    })
  )
  .use(piniaPluginPersistedstate)
  .use(({ store }) => {
    stores.push(store);
  });

/**
 * Resets all Pinia stores except those specified in the leave parameter
 * @param leave - Array of store names to exclude from reset
 */
export const resetAllStores = (leave: StoresEnum[] = []): void => {
  const storesToReset = stores.filter((store) => !leave.includes(store.$id as StoresEnum));
  storesToReset.forEach((store) => store.$reset());
};

export const resetAllLoadingStates = (): void => {
  stores.forEach((store) => {
    if (store.$state && 'isLoading' in store.$state) {
      store.$patch((state) => {
        (state as any).isLoading = false;
      });
    }
  });
};

export const calculateAllStoresSize = (): number => {
  return stores.reduce((acc, store) => {
    return acc + JSON.stringify(store.$state).length;
  }, 0);
};

export * from './auth.pinia';
export * from './app.pinia';
export * from './chat.pinia';
export * from './meet.pinia';
export * from './messenger.pinia';
export * from './user.pinia';
export * from './file.pinia';
export * from './network.pinia';
export * from './feed.pinia';
export * from './group.pinia';
export * from './notification.pinia';
export * from './page.pinia';
export * from './event.pinia';
export * from './topics.pinia';
export * from './search.pinia';
export * from './badge.pinia';
export * from './projects.pinia';
export * from './wiki.pinia';
export * from './office.pinia';
export * from './ai.pinia';
export * from './menu.pinia';
export * from './registration.pinia';
export * from './admin.pinia';
export * from './doc.pinia';
export * from './links.pinia';

export enum StoresEnum {
  AUTH = 'auth',
  APP = 'app',
  USER = 'user',
  NETWORK = 'network',
  CHAT = 'chat',
  MEET = 'meet',
  MESSENGER = 'messenger',
  FILE = 'file',
  FEED = 'feed',
  GROUP = 'group',
  NOTIFICATION = 'notification',
  PAGE = 'page',
  EVENT = 'event',
  TOPICS = 'topics',
  SEARCH = 'search',
  BADGE = 'badge',
  PROJECTS = 'projects',
  WIKI = 'wiki',
  OFFICE = 'office',
  AI = 'ai',
  MENU = 'menu',
  REGISTRATION = 'registration',
  ADMIN = 'admin',
  DOC = 'doc',
  LINKS = 'links',
}
